import Vue from 'vue';
import VueRouter from 'vue-router';
import Desktop from '../views/Desktop.vue';
import Mobile from '../views/Mobile.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Mobile',
    component: Mobile,
  },
  {
    path: '/',
    name: 'Desktop',
    component: Desktop,
  },
  {
    path: '/timesheet/app/policy',
    name: 'timesheetPolicy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/timesheetpolicy.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
