<template>
  <div class="page">
    <div class="home">
      <img
        src="../assets/logo-blue-sect.svg"
        alt="blured logo"
        class="blurLogo"
      />
      <div class="top">
        <div class="logo">
          <img
            src="../assets/icon.svg"
            alt="logo"
            class="icon"
            :class="{ isCollapsed: isCollapsed }"
          />
          <div class="textPart">
            <img
              src="../assets/text.svg"
              alt="logo"
              class="text"
              :class="{ isCollapsed: isCollapsed }"
            />
            <p
              v-touch:tap="changeLang"
              class="langLink"
              :class="{ isCollapsed: isCollapsed }"
            >
              {{ lang === "fr" ? "FRANCAIS" : "ENGLISH" }}
            </p>
          </div>
        </div>
      </div>
      <div class="bottom">
        <p class="subtitle" :class="{hidden: showMessage}">{{ t.ORBITE }} {{ dots }}</p>
        <Arrow :isCollapsed="isCollapsed" :section="currentSection" />
      </div>
    </div>
    <div class="home two">
      <p
        class="subtitle"
        :class="{ 'input-focused': inputFocused, loading: loading }"
      >
        {{ t.DESC }} <span>{{ t.LAUNCH }}</span>
      </p>
      <input
        v-model="email"
        placeholder="Email"
        id="email"
        type="email"
        class="email"
        :class="{ notEmpty: notEmpty, loading: loading }"
      />
      <button
        v-touch:tap="submit"
        class="button"
        :class="{ notEmpty: notEmpty, loading: loading }"
      >
        <transition name="fade" mode="out-in">
          <p v-if="!loading">{{ t.SUBMIT }}</p>
          <div v-else class="square"></div>
        </transition>
      </button>
    </div>
    <div v-touch:tap="closePopup">
      <transition name="fade">
        <Popup v-if="showMessage" :isSuccess="isSuccess" :message="message" :isMobile="true"/>
      </transition>
    </div>
  </div>
</template>

<script>
import intel from '../utils/trads';
import store from '../store/index';
import Arrow from '../components/Arrow.vue';
import Popup from '../components/Respopup.vue';
import {
  db,
  collection,
  serverTimestamp,
  addDoc,
  query,
  where,
  getDocs,
} from '../firebase';

export default {
  components: {
    Arrow,
    Popup,
  },
  data() {
    return {
      dots: '..',
      dotLooper: null,
      loopIntervalDuration: 300,
      email: '',
      currentSection: 0,
      loading: false,
      inputFocused: false,
      message: '',
      showMessage: false,
      isSuccess: false,
      isCollapsed: false,
    };
  },
  mounted() {
    this.dotLooper = setInterval(this.dotter, this.loopIntervalDuration);
    window.addEventListener('scroll', () => {
      if (window.scrollY > window.innerHeight / 5) {
        this.isCollapsed = true;
      } else {
        this.isCollapsed = false;
      }
      if (window.scrollY > window.innerHeight / 2) {
        this.currentSection = 1;
      } else {
        this.currentSection = 0;
      }
    });
    document.getElementById('email').addEventListener('focus', () => {
      window.scrollTo({ top: window.innerHeight / 2 + 50, behavior: 'smooth' });

      this.inputFocused = true;
    });
    document.getElementById('email').addEventListener('blur', () => {
      setTimeout(() => {
        window.scrollTo({ top: window.innerHeight, behavior: 'smooth' });
      }, 200);
      this.inputFocused = false;
    });
  },
  computed: {
    isValidEmail() {
      // eslint-disable-next-line no-useless-escape
      const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return reg.test(this.email);
    },
    notEmpty() {
      if (this.email !== '' && this.isValidEmail) {
        return true;
      }
      return false;
    },
    t() {
      return intel[this.lang];
    },
    lang() {
      return store.state.lang;
    },
  },
  destroyed() {
    clearInterval(this.dotLooper);
  },
  methods: {
    closePopup() {
      this.showMessage = false;
    },
    async submit() {
      this.loading = true;
      try {
        let status;
        const q = query(
          collection(db, 'emails'),
          where('email', '==', this.email),
        );

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          await addDoc(collection(db, 'emails'), {
            email: this.email,
            createdAt: serverTimestamp(),
          });
          status = 0;
        } else {
          status = 1;
        }

        setTimeout(() => {
          this.status = status;
          this.email = '';
          this.loading = false;
          this.displayMessage();
        }, 1000);
      } catch (e) {
        this.status = 2;
        this.loading = false;
        this.displayMessage();
      }
    },
    displayMessage() {
      let msgType;
      switch (this.status) {
        case 0:
          msgType = 'SUCCESS';
          break;
        case 1:
          msgType = 'EXISTS';
          break;
        case 2:
          msgType = 'ERROR';
          break;

        default:
          msgType = 'ERROR';
          break;
      }
      const msg = this.t[msgType];
      this.message = msg;
      this.showMessage = true;
      this.isSuccess = this.status === 0;
      setTimeout(() => {
        this.message = '';
        this.showMessage = false;
        this.isSuccess = false;
      }, 6000);
    },
    changeLang() {
      store.commit('changeLang');
    },
    dotter() {
      let d = this.dots;
      if (d === '.') {
        d = '..';
      } else if (d === '..') {
        d = '...';
      } else if (d === '...') {
        d = '';
      } else {
        d = '.';
      }
      this.dots = d;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../variables.scss";

button.button {
  opacity: 0;
  width: 80vw;
  height: 60px;
  transform: translateY(-80px);
  transition: all 300ms;
  transition-timing-function: cubic-bezier(0.78, 0.01, 0.26, 0.99);
  &.notEmpty {
    opacity: 1;
    transform: translateY(20px);
    &.touch {
      transform: translateY(15px);
      box-shadow: $blue-shadow-hover;
    }
    &.loading {
      width: 60px;
      padding: 0px;
      border-radius: 15px;
      transform: translateY(-50px) scale(1.7);
      background: radial-gradient(
        circle at 50% 50%,
        transparent 47%,
        $blue 30%
      );
    }
  }
  p {
    font-family: tussilago, sans-serif;
  }
}

.home {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 40px;
  &.two {
    align-items: center;
    justify-content: center;
    p.subtitle {
      opacity: 1;
      font-size: 15px;
      text-align: left;
      margin: 0px;
      color: $blue;
      font-weight: 500;
      max-width: 600px;
      word-wrap: break-word;
      margin-bottom: 20px;
      transform: translateY(0px);
      transition: all 300ms;
      transition-delay: 100ms;
      transition-timing-function: cubic-bezier(0.78, 0.01, 0.26, 0.99);
      span {
        font-weight: 900;
      }
      &.input-focused {
        transform: translateY(-20px);
      }
      &.loading {
        opacity: 0;
      }
    }
    input.email {
      width: 80vw;
      text-align: center;
      z-index: 999;
      padding: 30px;
      font-size: 22px;
      border-radius: 20px;
      background-color: rgba(255, 255, 255, 0.6);
      color: $blue;
      text-transform: uppercase;
      font-family: tussilago, sans-serif;
      font-weight: 900;
      box-shadow: 0px 14px 59px rgba(5, 0, 226, 0.35);
      transform: translateY(0px);
      transition: all 300ms;
      transition-timing-function: cubic-bezier(0.78, 0.01, 0.26, 0.99);
      &::placeholder {
        color: rgba(5, 0, 226, 0.15);
      }
      &.notEmpty {
        background-color: rgba(255, 255, 255, 1);
        &.loading {
          opacity: 0;
        }
      }
      &:focus-visible {
        box-shadow: 0px 34px 59px rgba(5, 0, 226, 0.15);
        background-color: rgba(255, 255, 255, 1);
        transform: translateY(-20px);
      }
    }
  }
  .blurLogo {
    opacity: 0.4;
    position: fixed;
    bottom: 0px;
    right: 0px;
    transform: rotate(90deg);
    height: 65vw;
    filter: blur(40px);
  }
}
.top {
  width: calc(100% - 300px);
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: space-between;
  position: fixed;
  //   .logo {
  //     margin-top: 20px;
  //     max-width: calc(100vw - 80px);
  //   }
  .logo {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    transition: all 300ms;
    transition-timing-function: cubic-bezier(0.78, 0.01, 0.26, 0.99);
    img {
      height: 35px;
      &.icon {
        transition: all 400ms;
        transition-timing-function: cubic-bezier(0.78, 0.01, 0.26, 0.99);
        transform: scale(1) translateX(0px) translateY(0px);
        &.isCollapsed {
          transform: scale(1.5) translateX(4px) translateY(4px);
        }
      }
      &.text {
        opacity: 1;
        margin-left: 10px;
        transition: all 400ms;
        transition-timing-function: cubic-bezier(0.78, 0.01, 0.26, 0.99);
        &.isCollapsed {
          transform: translateX(100vw);
          opacity: 0;
        }
      }
    }
    .textPart {
      position: relative;
    }
  }
  .langLink {
    z-index: 99;
    font-size: 13px;
    margin: 0px;
    color: $blue;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;
    transition: all 400ms;
    opacity: 0;
    transform: translateX(100vw);
    transition-timing-function: cubic-bezier(0.78, 0.01, 0.26, 0.99);
    &.isCollapsed {
      opacity: 1;
      transform: translateX(0px);
    }
    &.touch {
      text-decoration: underline;
    }
  }
}
.bottom {
  position: fixed;
  bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  p.subtitle {
    font-size: 15px;
    margin: 0px;
    color: $blue;
    font-weight: 900;
    &.hidden {
      opacity: 0;
      transition: all 400ms;
      transition-timing-function: cubic-bezier(0.78, 0.01, 0.26, 0.99);
    }
  }
  .shape {
    height: 70px;
    width: 14px;
    background-color: $blue;
    border-radius: 100px;
  }
}
</style>
