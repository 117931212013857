const intel = {
  fr: {
    ORBITE: 'Bientôt en orbite',
    SUBMIT: 'Valider',
    DESC: 'Donnez-nous votre email pour être prevenu du',
    LAUNCH: 'lancement !',
    SUCCESS: 'Votre email a été ajouté !',
    EXISTS: 'Nous avons déjà cet email',
    ERROR: 'Une erreur inattendue est survenue',
  },

  en: {
    ORBITE: 'Soon in orbite',
    SUBMIT: 'Submit',
    DESC: 'Give us your email to be notified of the',
    LAUNCH: 'launch !',
    SUCCESS: 'Your email has been added !',
    EXISTS: 'We already have this email',
    ERROR: 'An unexpected error has occured',
  },
};

export { intel as default };
