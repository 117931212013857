<template>
  <div id="app">
    <!-- <Mobile v-if="isMobile" />
    <Desktop v-else /> -->
    <router-view />
  </div>
</template>

<script>
// import Desktop from './views/Desktop.vue';
// import Mobile from './views/Mobile.vue';

export default {
  // components: {
  //   Desktop,
  //   Mobile,
  // },
  mounted() {
    // const vh = window.innerHeight * 0.01;
    // document.documentElement.style.setProperty('--vh', `${vh}px`);
    // window.addEventListener('resize', () => {
    //   const h = window.innerHeight * 0.01;
    //   document.documentElement.style.setProperty('--vh', `${h}px`);
    // });
    const route = this.$route.name;
    console.log({ route });
    if (route === 'Mobile' || route === 'Desktop') {
      if (this.isMobile) {
        this.$router.push({
          name: 'Mobile',
        });
      } else {
        this.$router.push({
          name: 'Desktop',
        });
      }
    }
  },
  computed: {
    isMobile() {
      return this.$isMobile();
    },
  },
};
</script>

<style lang="scss">
@import url("https://use.typekit.net/ilb0ump.css");
@import "./variables.scss";
#app {
  font-family: tussilago, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  height: 100vh;
  padding: 0px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 100px white inset !important;
  box-shadow: 0 0 0 100px white inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: $blue !important;
}

input {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

* {
  box-sizing: border-box;
}

body,
html {
  padding: 0px;
  margin: 0px;
  background-color: $light-blue;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
  transition-timing-function: cubic-bezier(0.78, 0.01, 0.26, 0.99);
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

button.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 18px 5px 18px;
  background: $blue;
  box-shadow: $blue-shadow;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 300ms;
  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    span.emoji {
      font-size: 30px;
      margin-left: 20px;
    }
  }
  &.withIcon {
    padding: 5px 18px 5px 24px;
    img {
      height: 20px;
      margin-left: 15px;
    }
  }
  &:hover {
    transform: translateY(-5px);
    box-shadow: $blue-shadow-hover;
  }
}
</style>
