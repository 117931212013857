import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang: 'fr',
    isMobile: false,
  },
  getters: {
    lang: (state) => state.lang,
    isMobile: (state) => state.isMobile,
  },
  mutations: {
    changeLang(state) {
      if (state.lang === 'fr') {
        state.lang = 'en';
      } else {
        state.lang = 'fr';
      }
    },
    setIsMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
  },
});
