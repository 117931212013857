<template>
  <div>
    <rive-canvas
      canvas-id="my-canvas"
      :class="{ loaded: isLoaded, isCollapsed: isCollapsed }"
      :canvas-width="30"
      :canvas-height="150"
      rive-file-src="scroll.riv"
      :autoplay="true"
      layout-fit="cover"
      layout-alignment="center"
      :animations="['scroll']"
      @load="handleEvent"
    />
  </div>
</template>

<script>
import RiveCanvas from 'vue-rive';

export default {
  props: {
    section: Number,
    isCollapsed: Boolean,
  },
  components: {
    RiveCanvas,
  },
  data() {
    return {
      riveInstance: null,
      isLoaded: false,
    };
  },
  mounted() {
    document.getElementById('my-canvas').addEventListener('click', () => {
      this.arrowClick();
    });
  },
  methods: {
    arrowClick() {
      if (this.section === 0) {
        window.scrollTo({ top: window.innerHeight, behavior: 'smooth' });
        this.riveInstance.play('downReverse');
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.riveInstance.play('upReverse');
      }
    },
    handleEvent(ev, rive) {
      if (ev.type === 'load') {
        this.riveInstance = rive;
        this.isLoaded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#my-canvas {
  position: fixed;
  bottom: 150px;
  right: 150px;
  opacity: 0;
  transform: scale(0.8) translateX(15%) translateY(15%);
  cursor: pointer;
  transition: all 300ms;
  transition-delay: 500ms;
  &:hover {
    transition-delay: 10ms;
    transform: scale(0.85) translateX(15%) translateY(15%);
  }
  &.loaded {
    opacity: 1;
    &.isCollapsed {
      opacity: 0;
    }
  }
}
@media screen and (max-width: 768px) {
  #my-canvas {
    position: fixed;
    right: 40px;
    bottom: 40px;
    opacity: 0;
    transform: scale(0.5) translateX(30px) translateY(70px);
    cursor: pointer;
    transition: all 300ms;
    transition-delay: 500ms;
    &:hover {
      transition-delay: 10ms;
      transform: scale(0.55) translateX(20px) translateY(60px);
  }
    &.loaded {
      opacity: 1;
      &.isCollapsed {
        opacity: 0;
      }
    }
  }
}
</style>
