import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  serverTimestamp,
  addDoc,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyB7s4EKa_p6K5ZjL_ngCZ8m2UFqIx0wn_0',
  authDomain: 'guyenne-technologies.firebaseapp.com',
  projectId: 'guyenne-technologies',
  storageBucket: 'guyenne-technologies.appspot.com',
  messagingSenderId: '82313330561',
  appId: '1:82313330561:web:6834e04937fbed9ddbb280',
  measurementId: 'G-MSR87PDFMM',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export {
  db,
  getFirestore,
  serverTimestamp,
  addDoc,
  collection,
  query,
  where,
  getDocs,
};
