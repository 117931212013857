<template>
  <div class="popup" :class="{ success: isSuccess, mobile: isMobile }">
    <div class="status" :class="{ success: isSuccess }"></div>
    <div class="mainCont">
      <p :class="{ success: isSuccess }">{{ message }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: String,
    isSuccess: Boolean,
    isMobile: Boolean,
  },
};
</script>

<style lang="scss" scoped>
@import "../variables.scss";
.popup {
  z-index: 999;
  position: fixed;
  bottom: 30px;
  left: 30px;
  width: calc(100vw - 60px);
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  background-color: rgba($color: #ffffff, $alpha: 0.8);
  box-shadow: $red-shadow-light;
  border-radius: 15px;
  padding: 10px;
  transition: all 300ms;
  transform: translateY(0px);
  &.success {
    box-shadow: $green-shadow-light;
  }
  &:hover {
    transform: translateY(10px);
  }
  .mainCont {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      color: $red;
      font-weight: 900;
      &.success {
        color: $green;
      }
    }
  }
  .status {
    margin-top: 10px;
    width: 100%;
    height: 5px;
    background-color: $red;
    box-shadow: $red-shadow;
    border-radius: 30px;
    &.success {
      background-color: $green;
      box-shadow: $green-shadow;
    }
  }
}
.popup.mobile {
  z-index: 999;
  position: fixed;
  bottom: 30px;
  left: 30px;
  width: calc(100vw - 60px);
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  background-color: rgba($color: #ffffff, $alpha: 0.6);
  box-shadow: $red-shadow-light;
  border-radius: 15px;
  padding: 10px;
  transition: all 300ms;
  transform: translateY(0px);
  &.success {
    box-shadow: $green-shadow-light;
  }
  &:hover {
    transform: translateY(10px);
  }
  .mainCont {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      color: $red;
      font-size: 12px;
      font-weight: 900;
      &.success {
        color: $green;
      }
    }
  }
  .status {
    margin-top: 10px;
    width: 100%;
    height: 5px;
    background-color: $red;
    box-shadow: $red-shadow;
    border-radius: 30px;
    &.success {
      background-color: $green;
      box-shadow: $green-shadow;
    }
  }
}
</style>
